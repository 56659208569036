nav {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    line-height: 1.5em;
}

nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    list-style-type: none;
    padding: 0;
    gap: 20px;
}

nav li {
    padding: 4px;
}

nav li.active a {
    border-bottom: 1px solid #fff;
}

