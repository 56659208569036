@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&family=Klee+One&family=New+Tegomin&family=Noto+Sans+JP:wght@100..900&display=swap');

body {
  background: #4d2e0b url("images/map-trimmed.jpg");
  background-size: auto 110%;
  background-attachment: fixed;
  background-repeat: repeat;
  background-position: center;
  font-family: "Alegreya", "New Tegomin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  letter-spacing: 0.04em;
  font-style: normal;
  color: white;
  text-align: center;
  font-size: 20px;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0;
}

#root {
  width: 100%;
  height: 100vh;
}

a {
  color: white;
  text-decoration: none;  
}

a:hover {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
}

p,
ul,
ol {
  font-size: 1.2rem;
  font-family: "New Tegomin", sans-serif;
  line-height: 1.8;
  margin-bottom: 1em;
  width: 100%;
}

.sp {
  display: none;
}

.link a {
  text-decoration: underline;
}

.instruction a {
  text-decoration: underline;
}

.container {
  min-height: 70vh;
  box-sizing: border-box;
  padding: 3rem 2rem;
  margin: 0;
  top: 0;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

main .intro {
  width: 100%;
}

.introduction {
  padding: 2rem 0;
}

.introduction p {
  font-size: 1.2rem;
}

.introduction p {
  margin-bottom: 1em;
}


.introduction.login {
  padding: 2rem 0;
}

.introduction.login h2 {
  font-size: 1.4rem;
}

.introduction.login p {
  font-size: 1rem;
}

.introduction.login p {
  margin-bottom: 1em;
}


.introduction ul,
.introduction ol {
  text-align: left;
  width: auto;
  font-size: 1.1rem;
}

.introduction h2 {
  font-size: 1.6rem;
  margin-bottom: 2em;
  font-weight: 400;
}

.introduction p {
  font-size: 1rem;
}

.audio_wrap {
  display: block;
  width: 100%;
}


/* Form style */

input {
  width: 90%; 
  padding: 10px 15px; 
  font-size: 20px;
  border-radius: 3px;
  border: 1px solid #af986f;
  box-sizing: border-box; 
  outline: none;
}

.error {
  display: block;
  margin: 0.5em 0 0;
  color: #ff0000;
  font-size: 16px;
}

/* ボタンにフォーカスがある場合のスタイル */
.btn:focus {
	background-color: rgb(56, 56, 56);
  outline: none;
}

.btn {
  color: #fff;
  display: block;
  margin: 1.6em auto;
  font-family: "New Tegomin", sans-serif;
  font-size: 20px;
  font-weight: bold;
  --radii: 0.5em;
  padding: 0.4em 1.4em 0.5em;
  min-width: 120px;
  transition: 0.2s;
  background-color: #000;
  border: none;
  border-radius: var(--radii);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  cursor:pointer;
}

.link {
  position: relative;
}

.link::after {
  content: '';
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.4em;
  margin-bottom: -0.1em;
  background-image: url("images/link.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
  
.btn:hover {
  background-color: #463216;
}

/* Map */

.googlemap {
  width: 80vw;
  margin: 1em auto 4em;
}

.caption-list ol {
  text-align: left;
  font-size: 1.2rem;
  margin: 6em 0;
}

.caption-list p {
  font-size: 1rem;
  margin: 6em 0 3em 0;
}

.caption-list {
  padding-bottom: 4em
}

.caption-list ol li {
  margin-bottom: 0.4em;
}

@media screen and (max-width: 749px) {
  .caption-list ol {
    font-size: 1rem;
    width: auto;
  }
}

#soundbgm {
  opacity: 0;
  height: 1px;
  overflow: hidden;
}

.audio_wrap {
  margin-top: 1em;
  margin-bottom: 8em;
}

.audio_wrap p {
  margin-bottom: 0;
  margin-top: 0;
}

.sound-controls {
  max-width: 132px;
  margin: -0.5em auto;
}

.sound-controls > button {
  background-color: #eeeeee;
  color: #000;
  background-image: url("images/sound_on.svg");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 8px center;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  border: 1px solid #ccc;
  width: 64px;
  float: left;
  padding: 5px 16px 5px 28px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: "Alegreya", "New Tegomin", sans-serif;
  -webkit-appearance: none;
  appearance: none;
}

.sound-controls button#btn_mute {
  background-image: url("images/sound_off.svg");
}

.sound-controls button.on {
  border-left: none;
}

.sound-controls button.off {
  border-right: none;
}

.sound-controls button.active {
  background-color: #ccc;
}

.sound-controls button:not(.active):hover {
  background-color: rgba(204, 204, 204, 0.7);
}


/* Footer */

footer {
  padding: 1em 0 1em;
  width: 100%;
  min-height: 10vh;
}

footer p {
  font-size: 1rem;
  margin: 0;
}

footer ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  list-style-type: none;
  padding: 0;
  gap: 0;
}

footer ul li a {
  /*width: 30%;*/
  border-right: 1px solid white;
}

footer ul li a {
  padding: 0px 16px;
}

footer ul li:last-child a {
  border-right: none;
}

footer ul li a {
  font-size: 1rem;
  font-weight: normal;
}

footer ul li a:hover {
  border-bottom: none;
}


@media screen and (max-width: 749px) {
  .sp {
    display: block;
  }
}


/* Loading animation */


.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 1);
  z-index: 1;
  top: 0;
}

.spinner {
  width: 32px;
  height: 32px;
  border: 2px solid;
  border-color: #ffffff transparent #ffffff transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

.spinner img {
  display: none;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}